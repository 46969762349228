import React from "react";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./HomePage/HomePage";
import Invitation from "./Invitation/Invitation";
import SharedPictures from "./SharedPictures/SharedPictures";
import Upgrade from "./Upgrade/Upgrade";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Upgrade />} path={"/upgrade"} />
        <Route element={<Upgrade />} path={"/uppgradera"} />
        <Route element={<Invitation />} path={"/i/:hash"} />
        <Route element={<SharedPictures />} path={"/s/:hash"} />
        <Route element={<HomePage />} index />
      </Routes>
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
