import axios from "axios";

import { apiUrl } from "../constants/config";
import { showError } from "./message";

export const post = async (endpoint, params = {}, source = "public") => {
  try {
    const url = `${apiUrl}/${source}/post/${endpoint}.php`;
    const response = await axios.get(url, { params });
    return response.data;
  } catch (err) {
    showError();
    return {};
  }
};

export const get = async (endpoint, params = {}, source = "public") => {
  try {
    const url = `${apiUrl}/${source}/get/${endpoint}.php`;
    const response = await axios.get(url, { params });
    return response.data;
  } catch (err) {
    showError();
    return {};
  }
};
