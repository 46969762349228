import React, { useEffect, useState } from "react";

import { get } from "../../../util/server";
import styles from "./swish.module.css";

function Swish({ paymentToken }) {
  const [success, setSuccess] = useState(false);
  const [checking, setChecking] = useState(false);

  let timeout = null;

  const openSwish = () => {
    const url = `swish://paymentrequest?token=${paymentToken}`;
    window.location.href = url;
  };

  useEffect(() => {
    clearTimeout(timeout);
  });

  const handleOpenSwish = () => {
    setChecking(true);
    checkPayment();
    openSwish();
  };

  const checkPayment = async () => {
    clearTimeout(timeout);
    const response = await get("payment", { token: paymentToken });
    if (response.success && response.isPaid) {
      setSuccess(true);
    } else {
      timeout = setTimeout(checkPayment, 2000);
    }
  };

  return (
    <div className={styles.container}>
      {success ? (
        <div className={styles.success}>
          Betalning genomförd! Du kan nu återgå till appen igen.
        </div>
      ) : checking ? (
        <div className={styles.checking}>Kontrollerar betalning...</div>
      ) : (
        <div className={styles.swishButton} onClick={handleOpenSwish}>
          Öppna Swish
        </div>
      )}
    </div>
  );
}

export default Swish;
