import React from "react";

import Image from "../../../images/logo.png";
import styles from "./logo.module.css";

function Logo({ width }) {
  return (
    <div className={styles.container} style={{ maxWidth: width }}>
      <img className={styles.logo} src={Image} />
      <div className={styles.contactContainer}>
        <a href="mailto:info@tripsandpics.se">KONTAKT</a>
      </div>
    </div>
  );
}

export default Logo;
