import React from "react";

import styles from "./types.module.css";

function Types({ items, onSelect }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Välj vilken typ du vill uppgradera till:
      </div>
      {items.map((item) => (
        <div
          className={styles.item}
          key={item.type}
          onClick={() => onSelect(item.type)}
        >
          <div className={styles.title}>{item.name}</div>
          <div className={styles.users}>{item.users} användare</div>
          <div className={styles.images}>{item.images} bilder/anv.</div>
          <div className={styles.prize}>{item.prize} kr/album</div>
        </div>
      ))}
    </div>
  );
}

export default Types;
