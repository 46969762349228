import { appStoreUrl, googlePlayUrl } from "../../../constants/storeUrls";
import AppStore from "../../../images/app-store.png";
import GooglePlay from "../../../images/google-play.png";
import styles from "./downloadApp.module.css";

export default function DownloadApp() {
  return (
    <div className={styles.container}>
      <a
        className={styles.link}
        href={appStoreUrl}
        rel="noreferrer"
        target="_blank"
      >
        <img className={styles.image} src={AppStore} />
      </a>
      <a
        className={styles.link}
        href={googlePlayUrl}
        rel="noreferrer"
        target="_blank"
      >
        <img className={styles.image} src={GooglePlay} />
      </a>
    </div>
  );
}
