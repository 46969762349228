import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { get } from "../../util/server";
import { Page } from "../shared";
import styles from "./sharedPictures.module.css";

function SharedPictures() {
  const params = useParams();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const response = await get("images", { hash: params?.hash });
      if (response.success) {
        setItems(response.images);
      }
    };
    fetchItems();
  }, [params?.hash, setItems]);

  const renderItem = (item) => {
    const imageUrl = `https://res.cloudinary.com/tripsandpics/image/upload/c_thumb,w_600/${item.image}`;
    return (
      <div className={styles.imageContainer} key={item?.image}>
        <img className={styles.image} src={imageUrl} />
      </div>
    );
  };

  return <Page className={styles.container}>{items.map(renderItem)}</Page>;
}

export default SharedPictures;
