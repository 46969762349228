import classNames from "classnames";
import React, { useState } from "react";

import { showError } from "../../../util/message";
import { get, post } from "../../../util/server";
import styles from "./form.module.css";
import Types from "./Types";

function Form({ onSuccess }) {
  const [code, setCode] = useState("");
  const [availableTypes, setAvailableTypes] = useState([]);
  const [submitting, setSubmitting] = useState("");

  const handleChange = (event) => setCode(event.target.value);
  const handleSubmit = async () => {
    if (!submitting) {
      setSubmitting(true);
      const response = await get("available_types", { code });
      if (response.success) {
        const availableTypes = response.availableTypes.filter(
          (item) => !item.disabled
        );
        if (availableTypes.length === 0) {
          showError("Albumet är redan ett guldalbum.");
          setSubmitting(false);
        } else {
          setAvailableTypes(availableTypes);
        }
      } else {
        showError("Kunde inte hitta albumet.");
        setSubmitting(false);
      }
    }
  };
  const handleTypeSelect = async (type) => {
    const response = await post("create_payment", { code, type });
    if (response.success) {
      onSuccess(response.paymentToken);
    } else {
      showError("Kunde inte genomföra betalning.");
    }
  };

  return (
    <div className={styles.container}>
      {availableTypes.length > 0 ? (
        <Types items={availableTypes} onSelect={handleTypeSelect} />
      ) : (
        <>
          <div>Ange albumets kod:</div>
          <input
            className={styles.input}
            onChange={handleChange}
            type="text"
            value={code}
          />
          <div
            className={classNames({
              [styles.submitButton]: true,
              [styles.disabledButton]: submitting,
            })}
            onClick={handleSubmit}
          >
            Hämta album
          </div>
        </>
      )}
    </div>
  );
}

export default Form;
