import React from "react";
import { useParams } from "react-router-dom";

import { appStoreUrl, googlePlayUrl } from "../../constants/storeUrls";
import { Page } from "../shared";
import styles from "./invitation.module.css";

function Invitation() {
  const params = useParams();

  return (
    <Page>
      <div className={styles.item}>
        <div className={styles.number}>1</div>
        <div className={styles.text}>
          Ladda hem appen från{" "}
          <a href={appStoreUrl} rel="noreferrer" target="_blank">
            App Store
          </a>{" "}
          eller{" "}
          <a href={googlePlayUrl} rel="noreferrer" target="_blank">
            Google Play
          </a>
          .
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.number}>2</div>
        <div className={styles.text}>
          Skapa ett konto och ange koden{" "}
          <span className={styles.code}>{params?.hash}</span>.
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.number}>3</div>
        <div className={styles.text}>
          Välkommen till ditt första album i Trips &amp; Pics!
        </div>
      </div>
    </Page>
  );
}

export default Invitation;
