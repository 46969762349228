import React from "react";

import Contact from "../Contact/Contact";
import Logo from "../Logo/Logo";
import styles from "./page.module.css";

function Page({ children, className, width }) {
  return (
    <div className={styles.container}>
      <Logo width={width} />
      <div className={styles.content} style={{ maxWidth: width || 500 }}>
        <div className={className}>{children}</div>
      </div>
      <Contact />
    </div>
  );
}

export default Page;
