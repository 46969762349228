import React from "react";

import Step1 from "../../images/step-1.jpg";
import Step2 from "../../images/step-2.jpg";
import Step3 from "../../images/step-3.jpg";
import Step4 from "../../images/step-4.jpg";
import { Page } from "../shared";
import DownloadApp from "./DownloadApp/DownloadApp";
import styles from "./homePage.module.css";

function HomePage() {
  return (
    <Page className={styles.container} width={800}>
      <h1 className={styles.title}>
        Trips & Pics - Skapa unika och delbara digitala fotoalbum!
      </h1>
      <p>
        Semesterresa, bröllop, fest eller en solig pick-nick med familjen? Med
        gratis-appen Trips & Pics skapar du enkelt ett unikt och delbart
        digitalt fotoalbum för varje nytt tillfälle! Du väljer med vem eller
        vilka du vill dela varje enskilt album!
      </p>
      <DownloadApp />
      <img className={styles.image} src={Step1} />
      <p>
        Det finns många tillfällen då vi vill alla dela och se varandras bästa
        bilder men utan att behöva dela bilderna med alla våra ”vänner” i social
        medier eller skapa långa tråkiga sms-trådar.
      </p>
      <ul className={styles.list}>
        <li>
          Vill du och vännerna exempelvis se varandras bilder från er härliga
          semesterresa eller helgens lyckade fest, men kanske vill du inte dela
          bilderna med svärmor, dina kollegor och alla andra ”vänner” du har i
          dina social medier? Inga problem, med gratis-appen Trips & Pics skapar
          du enkelt ett unikt och delbart digitalt fotoalbum för varje nytt
          tillfälle! Du väljer med vem eller vilka du vill dela och skapa varje
          enskilt album!
        </li>
        <li>
          Eller är det äntligen dags för ert bröllop eller kanske din
          50-årsfest? Många gäster kommer säkert ta fantastiska bilder som
          tillsammans kan bli ett minne för livet! Med gratis-appen Trips & Pics
          kan du ge alla dina gäster möjlighet att tillsammans med dig skapa ett
          unikt fotoalbum till vilket alla gäster enkelt bidrar med sina bästa
          bilder och som bara du och dina gäster kan se!
        </li>
        <li>
          Är det äntligen dags den årliga golfresan med grabbarna eller kanske
          din efterlängtade spa-weekend med väninnorna? Självklart vill ni alla
          se de bästa bilderna som togs men kanske utan tråkiga sms-trådar och
          utan att visa allt för kollegorna, grannarna och alla andra ”vänner” i
          dina sociala medier. Inga problem, med gratis-appen Trips & Pics
          skapar du enkelt ett unikt och delbart digitalt fotoalbum som bara ni
          kan se, dela och fylla med många härliga minnen!
        </li>
      </ul>
      <p>
        Med gratis-appen Trips & Pics skapar du enkelt ett unikt och delbart
        digitalt fotoalbum för varje nytt tillfälle! Du väljer med vem eller
        vilka du vill dela varje enskilt album och därefter laddar ni enkelt upp
        era favoritbilder som bara du och dina utvalda vänner kan se och dela!
      </p>
      <div className={styles.images}>
        <img src={Step3} />
        <img src={Step4} />
      </div>
      <p>
        Varje Trips & Pics-album kan namnges och ges en kort beskrivning.
        Därtill kan ni lägga till en beskrivande text till varje enskild bild!
        Varje album och varje bild i Trips & Pics är även sökbar via både
        kronologisk lista och via karta!
      </p>
      <p>
        <b>Enkelt att komma igång med Trips & Pics!</b>
      </p>
      <ol className={styles.list}>
        <li>
          Ladda ner appen Trips & Pics och registrera dig, självklart helt
          gratis!
        </li>
        <li>
          Skapa ett album! Välj Gratisversionen Brons eller betala en
          engångsavgift enkelt med Swish för att kunna nyttja Silver- eller
          Guldversionerna. De sistnämnda versionerna passar perfekt om ni skall
          vara fler användare och få utrymme för fler bilder. Du kan självklart
          alltid uppgradera.
        </li>
        <li>Ladda upp dina utvalda favoritbilder!</li>
        <li>
          Välj vem eller vilka utvald vänner du vill bjuda in och som också ska
          kunna lägga till och se bilderna i just detta album!
        </li>
        <li>
          Tillsammans skapar och delar ni nu enkelt ett gemensamt och unikt
          digitalt fotoalbum, bara för er!
        </li>
        <li>
          Alla kan alltid enkelt lägga till eller ta bort bilder som ni själva
          laddat upp!
        </li>
        <li>Alla kan alltid enkelt bjuda in fler vänner till ert album!</li>
      </ol>
      <img className={styles.image} src={Step2} />
      <p>
        Skapa nu ett nytt album för varje semesterresa, för varje fest eller
        bara för ett tillfälle vill dela med dem du väljer!
      </p>
      <p>
        <b>Välkommen till Trips & Pics!</b>
      </p>
      <DownloadApp />
      <a className={styles.link} href="/upgrade">
        Vill du uppgradera ett album?
      </a>
      <a
        className={styles.link}
        href="//www.facebook.com/tripsandpics.se"
        rel="noreferrer"
        target="_blank"
      >
        Läs gärna mer om Trips &amp; Pics och följ oss på vår Facebook-sida!
      </a>
    </Page>
  );
}

export default HomePage;
