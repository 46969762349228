import React from "react";

import { contactMail } from "../../../constants/config";
import styles from "./contact.module.css";

function Contact() {
  return (
    <div className={styles.container}>
      Feedback? Kontakta <a href={`mailto:${contactMail}`}>{contactMail}</a>
    </div>
  );
}

export default Contact;
