import React, { useState } from "react";

import { Page } from "../shared";
import Form from "./Form/Form";
import Swish from "./Swish/Swish";

function Upgrade() {
  const [paymentToken, setPaymentToken] = useState(null);
  const onSuccess = (paymentToken) => setPaymentToken(paymentToken);
  const content = paymentToken ? (
    <Swish paymentToken={paymentToken} />
  ) : (
    <Form onSuccess={onSuccess} />
  );
  return <Page>{content}</Page>;
}

export default Upgrade;
